import {createRouter, createWebHistory} from 'vue-router'
import Main from '../static/Main'

const routes = [
    {
        path: '/',
        name: 'Main',
        component: Main

    },
    // --------------------------- Work Approach Redirect ---------------------------- //
    {
        path: '/work-approach/',
        name: 'work-approach',
        component: () => import('../static/ApproachWork')
    },
    {
        path: '/process/',
        redirect: '/work-approach/'
    },
    // ------------------------------------------------------------------------------- //
    {
        path: '/cases/',
        name: 'cases',
        component: () => import('../static/Cases'),
        children: [
            {
                path: '/cases/tnmk/',
                name: 'TNMK',
                component: () => import('../cases/TNMK'),
            },
            {
                path: '/cases/d4u/',
                name: 'D4U',
                component: () => import('../cases/D4U'),
            },
            {
                path: '/cases/Kultura_Kavy/',
                name: 'KulturaKavy',
                component: () => import('../cases/KulturaKavy'),
            },
            {
                path: '/cases/ukrpol_ua/',
                name: 'ukrpol',
                component: () => import('../cases/Ukrpol'),
            },
            {
                path: '/cases/karpaty_ua/',
                name: 'karpaty_ua',
                component: () => import('../cases/Karpaty')
            },
            {
                path: '/portfolio/karpaty_ua/',
                redirect: '/cases/karpaty_ua/'
            },
            {
                path: '/cases/architectural-company-archimatika/',
                name: 'architectural-company-archimatika',
                component: () => import('../cases/Archimatika')
            },
            {
                path: '/portfolio/archimatika/',
                redirect: '/cases/architectural-company-archimatika/'
            },
            {
                path: '/cases/bigboard/',
                name: 'bigboard',
                component: () => import('../cases/BidBoard')
            },
            {
                path: '/portfolio/bigboard/',
                redirect: '/cases/bigboard/'
            },
            {
                path: '/cases/big_idea/',
                name: 'big_idea',
                component: () => import('../cases/BigIdea')
            },
            {
                path: '/portfolio/big_idea/',
                redirect: '/cases/big_idea/'
            },
            {
                path: '/cases/hackenproof/',
                name: 'hackenproof',
                component: () => import('../cases/Hackenproof')
            },
            {
                path: '/portfolio/hackenproof/',
                redirect: '/cases/hackenproof/'
            },
            {
                path: '/cases/residential-area-manhattan/',
                name: 'residential-area-manhattan',
                component: () => import('../cases/Manhattan')
            },
            {
                path: '/portfolio/manhattan/',
                redirect: '/cases/residential-area-manhattan/'
            },
            {
                path: '/cases/oe/',
                name: 'oe',
                component: () => import('../cases/Oe')
            },
            {
                path: '/portfolio/oe/',
                redirect: '/cases/oe/'
            },
            {
                path: '/cases/uis/',
                name: 'uis',
                component: () => import('../cases/Uis')
            },
            {
                path: '/portfolio/uis/',
                redirect: '/cases/uis/'
            },
            {
                path: '/cases/construction-company-vertical/',
                name: 'construction-company-vertical',
                component: () => import('../cases/Vertical')
            },
            {
                path: '/portfolio/vertical/',
                redirect: '/cases/construction-company-vertical/'
            },
            {
                path: '/cases/teple-misto/',
                name: 'teple-misto',
                component: () => import('../cases/WarmCity')
            },
            {
                path: '/portfolio/warm-city/',
                redirect: '/cases/teple-misto/'
            },
            {
                path: '/cases/oversecured/',
                name: 'oversecured',
                component: () => import('../cases/Oversecured')
            },
            {
                path: '/cases/lobbyx/',
                name: 'lobbyx',
                component: () => import('../cases/LobbyX')
            },
            {
                path: '/cases/kochut/',
                name: 'kochut',
                component: () => import('../cases/Kochut')
            },
            {
                path: '/cases/palac-potocki/',
                name: 'palac-otocki',
                component: () => import('../cases/PalacPotocki')
            },
        ]
    },
    {
        path: '/career/',
        name: 'career',
        component: () => import('../static/Career')
    },
    /* {
        path: '/privacy-policy-ua/',
        name: 'privacy-policy-ua',
        component: () => import('../static/PrivacyPolicyUa')
    }, */
    /* {
        path: '/terms-and-conditions-ua/',
        name: 'terms-of-use-ua',
        component: () => import('../static/TermsOfUseUa')
    }, */
    /* {
        path: '/cookie-policy-ua/',
        name: 'cookie-policy-ua',
        component: () => import('../static/CookiePolicyUa')
    }, */
    {
        path: '/contacts/',
        name: 'contacts',
        component: () => import('../static/Contact')
    },
    {
        path: '/see-you-soon/',
        name: 'SeeYouSoon',
        component: () => import('../components/SeeYouSoon')
    },
    {
        path: '/webflow/',
        name: 'webflow',
        component: () => import('../static/Webflow')
    },
    {
        path: '/404/',
        name: 'NotFound',
        component: () => import('../static/NotFound')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../static/NotFound' )
    },
]

const router = createRouter({
    // mode: history,
    history: createWebHistory(process.env.BASE_URL),
    routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior: function(to, from, savedPosition) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },

})

export default router
