<template>
  <div class="view-more__item">
    <div class="tabs__item" @click="$router.push(`${pathToCase}`)">
      <div class="tabs__image">
        <picture>
          <source :srcset="projectImageWebp" type="image/webp">
          <img
              class="view-more__item-image"
              :src="projectImagePng"
              alt=""
              loading="lazy"
          />
        </picture>
      </div>

      <div class="tabs__body">
        <div class="tabs__title">{{ $t(projectTitle) }}</div>

        <ButtonWhite 
            :buttonText= "$t('main_page.portfolio_section.read_more')"
            :buttonLink="`${pathToCase}`"
            class="tabs__button"
          />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonWhite from "./buttons/ButtonWhite2.vue";

export default {
  name: "RecentProject2",
  components: {ButtonWhite},
  props: {
    projectImageWebp: {
      type: String,
    },
    projectImagePng: {
      type: String,
    },
    projectTitle: {
      type: String,
    },
    projectDescription: {
      type: String,
    },
    projectText: {
      type: String,
    },
    pathToCase: {
      type: String
    }
  },
}
</script>

<style lang="scss" scoped>

@import "../base/styles/media";

.tabs{
  &__body{
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__title{
    font-size: 24px;
    font-weight: 700;
    display: -webkit-box;
    overflow-y: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
    @media(max-width: 575px) {
      font-size: 20px;
    }
  }
  &__text{
    font-size: 18px;
    display: -webkit-box;
    overflow-y: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    @media(max-width: 575px) {
      font-size: 14px;
    }
  }
  
  &__button{
    width: fit-content;
  }
  &__item{
    width: 100%;
    height: 288px;
    overflow: hidden;
    position: relative;
 
  }
  &__image{
    img{
      width: 100%;
      max-height: 288px;
      object-fit: cover;
    }
    filter: brightness(50%);
  }
}
</style>
