<template>
  <section id="sec6">
    <div class="faq">
      <div class="container">
        <div class="faq__inner">
          <div class="faq__title">
            <picture>
              <source srcset="../assets/faq_ellipse.webp" type="image/webp">
              <img
                  src="../assets/faq_ellipse.png"
                  class="faq__circle"
                  alt=""
                  loading="lazy"
              />
            </picture>
            <div class="faq__bg">
              <h2 v-support-webp class="faq__text">FAQs</h2>
              <div class="faq__filter">
                <picture>
                  <source srcset="../assets/faq_bg.webp" type="image/webp">
                  <img
                      class="view-more__item-image"
                      src="../assets/faq_bg.png"
                      alt=""
                      loading="lazy"
                  />
                </picture>
              </div>
            </div>
          </div>
          <div class="faq__list">
            <div class="faq__body" v-for="(faq, index) in faqList" :key="index">
              <div>
                <h3 class="faq-q">{{ $t(faq.question) }}</h3>
              </div>
              <div class="faq-a">{{ $t(faq.answer) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Faq",
  data() {
    return {
      faqList: Array.from(Array(3)).map((serviceItem, index) => {
        return {
          index,
          question: `main_page.faq_section.faq_list[${index}].question`,
          answer: `main_page.faq_section.faq_list[${index}].answer`
        }
      })
    }
  },
}
</script>

<style lang="scss">
.faq {
  padding-top: 160px;

  @Media(max-width: 575px){
    padding-top: 118px;
  }
}


@media(min-width: 992px){
  .faq__inner{
    display: flex;
  }
}


.faq__title {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 0 1 50%;
  position: relative;

  @media(max-width: 575px){
    margin-left: -12px;
    margin-right: -12px;
  }
}

.faq__circle{
  width: 280px;
  height: 200px;
  position: absolute;
  bottom: 0;
  right: 60px;
  z-index: -5;

  @media(max-width: 1399px){
    width: 230px;
    height: 170px;
  }
  @media(max-width: 575px){
    width: 280px;
    height: 200px;
    right: 20px;
  }
}

@media(min-width: 992px){
  .faq__list{
    margin-bottom: 100px;
  }
}

.faq__bg{
  width: 100%;
  height: 100%;
  margin-right: 120px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @media(max-width: 575px){
    margin-right: 80px;
  }
}

.faq__filter{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -2;

    img{
      display: none;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -4;

      @media(max-width: 1199px){
        width: 150%;
      }

      @media(max-width: 991px){
        width: 80%;
      }

      @media(max-width: 767px){
        width: 100%;
      }

      @media(max-width: 575px){
        width: 150%;
      }
    }

    @supports (backdrop-filter: blur(30px)) {
      & {
        backdrop-filter: blur(30px);
        -webkit-filter: blur(30px);
        -moz-filter: blur(30px);
        -o-filter: blur(30px);
        -ms-filter: blur(30px);
        filter: blur(30px);
      }
    }

    @supports not (backdrop-filter: blur(30px)){
      & img {
        display: block;
      }
    }
}

.faq{
  &__text{
    font-size: 170px;
    font-weight: 700;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    line-height: 160px;
    margin-bottom: 110px;
    margin-right: -21px;
    transform: rotate(180deg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width: 991px){
      font-size: 150px;
      line-height: 146px;
    }
  }

  .yes__webp{
    background-image: url("../assets/faq_tex_bg.webp");
  }

  .no__webp{
    background-image: url("../assets/faq_tex_bg.png");
  }
}



.faq__body{
  margin-top: 72px;
  padding: 0px 36px 0 15px;

  @media(max-width: 575px){
    padding: 0px 36px 0 0px;
  }
}

.faq-q {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #2E3139;
  margin-top: 16px;

  @media(max-width: 991px){
    font-size: 22px;
    line-height: 30px;
  }


  @media(max-width: 575px){
    font-size: 20px;
    line-height: 30px;
  }
}
.faq-a {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #2E3139;
  max-width: 580px;
  margin-top: 10px;

  @media(max-width: 1399px){
    max-width: 500px;
  }

  @media(max-width: 991px){
    max-width: 100%;
  }

  @media(max-width: 575px){
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
