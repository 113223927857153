<template>

  <div class="wrapper">
    <MainScreenVideo @makeAnchorsVisible="makeAnchorsVisible()" />
    <Services />
    <PartnerAndClient/>
    <Advantages/>
    <RecentProjects/>
    <SocialAndWork/>
    <Faq/>
    <Contact/>
  </div>

  <Transition>
    <ul ref="anchor-links" class="anchor__links" v-if="this.isAnchorsVisible">
        <li class="anchor__list" v-for="(link, index) in anchorLinks" :key="link.id">
          <a class="anchor__item" @click="goToSection(index)" :class="{'active-link': link.isViewed}" :data-section-name="link.sectionName"></a>
        </li>
    </ul>
  </Transition>

  <div class="privacy-policy-ua__pop-up pop-up">
    <p>Наш вебсайт використовує файли cookies для персоналізації контенту та аналізу  трафіку. Дізнайтеся деталі на сторінці <router-link to="/cookie-policy-ua/">Політика використання файлів Cookie.</router-link></p>
    <p>У випадку згоди з нашою політикою використання файлів cookies, ви можете відхилити або відкликати її в будь-який час. У разі відмови — деякі функції сайту можуть бути недоступними.</p>

    <div class="pop-up__buttons">
      <button @click="this.isAgree(true)">Прийняти</button>
      <button @click="this.isAgree(false)">Відхилити</button>
    </div>
  </div>
</template>

<script>
import MainScreenVideo from "../components/MainScreenVideo";
import Services from "../components/Services";
import Advantages from "../components/Advantages";
import PartnerAndClient from "../components/PartnerAndClient";
import RecentProjects from "../components/RecentProjects2";
import SocialAndWork from "../components/SocialAndWork";
import Faq from "../components/Faq";
import Contact from "../components/Contact_1";

import { useHead } from '@vueuse/head'
import {computed, reactive} from "vue";


export default {
  name: 'Main',
  setup() {
    const siteData = reactive({
      title: "Software development services from Ukraine | Webmil",
      description: "We offer services for established businesses and startups, such as digital solutions for iOS, Android, and the web. Contact us: info@webmil.eu",
      keywords: "web production, outsource, eastern europe, ukraine, digital solutions"
    })

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords),
        },
      ],
    })
  },
  data(){
    return{
      anchorLinks: [
        {id: 1, sectionName: "sec1", isViewed: false},
        {id: 2, sectionName: "sec2", isViewed: false},
        {id: 3, sectionName: "sec3", isViewed: false},
        {id: 4, sectionName: "sec4", isViewed: false},
        {id: 5, sectionName: "sec5", isViewed: false},
        {id: 6, sectionName: "sec6", isViewed: false},
        {id: 7, sectionName: "sec7", isViewed: false},
      ],
      isAnchorsVisible: false
    }
  },
  components: {
    MainScreenVideo,
    Contact,
    Faq,
    PartnerAndClient,
    Services,
    Advantages,
    RecentProjects,
    SocialAndWork
  },
  methods: {
    goToSection(id){
      this.anchorLinks = this.anchorLinks.map((item, i) => {
       if(i === id) {
          item.isViewed = true
          window.scrollTo(
              {
                top: document.getElementById(item.sectionName).offsetTop,
                behavior: 'smooth'
              }
          )
        } else {
          item.isViewed = false
        }

        return item
      })
    },

    scrollSection(){
      this.anchorLinks = this.anchorLinks.map((item, index) => {
        let section = document.getElementById(item.sectionName)

        if(!section){
          return false
        }

        let rect = section.getBoundingClientRect()

        if(index === 0) {
          if (this.isSectionVisible(rect)) {
              item.isViewed = true
              this.isAnchorsVisible = true
          } else {
              item.isViewed = false
              this.isAnchorsVisible = false
          }
        } else {
          if (this.isSectionVisible(rect)) {
              item.isViewed = true
              this.isAnchorsVisible = true
            } else {
              item.isViewed = false
          }
        }

        return item
      })
    },
    isSectionVisible(rect){
      return rect.top < 230 && rect.bottom > 230
    },
    makeAnchorsVisible(){
      this.isAnchorsVisible = true
      this.anchorLinks[0].isViewed = true
    },
    isAgree(isAgree){
      let uaPrivacyPopUp = document.querySelector(".privacy-policy-ua__pop-up");
      uaPrivacyPopUp.classList.remove("pop-up__active");
      console.log(isAgree);
    }
  },
  mounted() {
    if(window.innerWidth > 991) {
      window.addEventListener("wheel", this.scrollSection)
    }

    const setUaCookies = () => {
      window.addEventListener("load", () => {
        let iubendaContainer = document.querySelector(".iubenda-cs-container");

        const isUkraineVersion = () => this.$root.$i18n.locale === 'ua';

        const setCookies = () => {
          if(isUkraineVersion()) {
            makePopUpVisible();
          } else {
            loadScripts();
          }
        }

        setCookies();

        console.log(iubendaContainer);
      })
    }

    setUaCookies();

    const loadScripts = () => {
      const scriptsArray = [
        {code: `(function (w,d) {
                  var loader = function () {
                      var s = d.createElement("script"),
                          tag = d.getElementsByTagName("script")[0];
                      s.src="https://cdn.iubenda.com/iubenda.js";
                      tag.parentNode.insertBefore(s,tag);
                  };
                  if(w.addEventListener) {
                      w.addEventListener("load", loader, false);
                  }else if(w.attachEvent) {
                      w.attachEvent("onload", loader);
                  } else {
                      w.onload = loader;
                  }
                 })(window, document);
               `,
          type: 'text/javascript',
        },
        {
          code: `(function (w,d) {
                    var loader = function () {
                        var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
                        s.src="https://cdn.iubenda.com/iubenda.js";
                        tag.parentNode.insertBefore(s,tag);
                    };

                    if(w.addEventListener) {
                        w.addEventListener("load", loader, false);
                    }else if(w.attachEvent)
                    {
                        w.attachEvent("onload", loader);
                    }else {
                        w.onload = loader;
                    }
                })
                 (window, document);
               `,
          type: 'text/javascript',
        },
        {
          code: `var _iub = _iub || [];
                  _iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"consentOnContinuedBrowsing":false,"floatingPreferencesButtonDisplay":"bottom-right","invalidateConsentWithoutLog":true,"lang":"en-GB","logViaAlert":true,"perPurposeConsent":true,"siteId":2610200,"whitelabel":false,"cookiePolicyId":54338031, "banner":{"acceptButtonCaptionColor":"#FFFFFF","acceptButtonColor":"#688e93","acceptButtonDisplay":true,"backgroundColor":"#FFFFFF","closeButtonRejects":true,"customizeButtonCaptionColor":"#4D4D4D","customizeButtonColor":"#e9eef0","customizeButtonDisplay":true,"explicitWithdrawal":true,"fontSize":"12px","listPurposes":true,"logo":null,"position":"float-bottom-right","rejectButtonCaptionColor":"#FFFFFF","rejectButtonColor":"#688e93","rejectButtonDisplay":true,"textColor":"#2e3139"}};
               `,
          type: 'text/javascript',
        }
      ]


      scriptsArray.forEach((script) => {
        let foo = document.createElement('script');
        foo.setAttribute("type", script.type)
        foo.innerHTML = script.code;
        document.body.appendChild(foo);

        console.log(foo);
      })

      let foo = document.createElement('script');
      foo.setAttribute("src", "//cdn.iubenda.com/cs/iubenda_cs.js")
      foo.setAttribute("type", "text/javascript")
      document.body.appendChild(foo);
    }

    const makePopUpVisible = () => {
      let uaPrivacyPopUp = document.querySelector(".privacy-policy-ua__pop-up");
      uaPrivacyPopUp.classList.add("pop-up__active");
    }
  },
  computed: {
  }

}
</script>

<style lang="scss">
#sec4{
  .container{
      padding-left: 12px;
      padding-right: 12px;
  }
}
.wrapper {
  position: relative;
}
.privacy-policy-ua__pop-up{
  position: fixed;
  right: 20px;
  bottom: -100%;
  background-color: white;
  z-index: 1;
  max-width: 600px;
  padding: 20px;
  transition: 0.4s all;
  opacity: 0;
  visibility: hidden;
  margin-left: 20px;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #2E3139;

    &:not(:first-child){
      margin-top: 23px;
    }

    & > a {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-decoration: underline;
      color: #3675AF;
    }
  }
}

.pop-up__buttons{
  display: flex;
  margin-top: 20px;
  button{
    flex: 0 1 50%;
    background-color: #2E3139;
    border: none;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    padding-top: 8px;
    padding-bottom: 8px;
    &:not(:first-child){
      margin-left: 10px;
    }
  }
}


.pop-up__active{
  bottom: 20px;
  opacity: 1;
  visibility: visible;
}


.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.active-link{
  background-color: rgba(23, 23, 23, 0.7) !important;
}

.anchor {
  @media(max-width: 991px){
    display: none;
  }
  &__links {
    position: fixed;
    top: 50%;
    right: 36px;
    transform: translateY(-50%);

    @media(max-width: 1399px){
      right: 25px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;

    &:not(:first-child){
      margin-top: 40px;
    }
  }

  &__item {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #D8E1E5;
    transition: 0.5s all;
  }
}
</style>
