<template>
  <section class="advantage" id="sec3">
    <div class="advantage__title">
      <div class="container">
        <h2 class="advantage__title-text">{{$t('main_page.advantages_section.title')}}</h2>
      </div>
      <picture>
        <source
            srcset="../assets/adventage_bg_desctop.webp"
            type="image/webp"
        >
        <img
            src="../assets/adventage_bg_desctop.png"
            alt=""
            loading="lazy"
        />
      </picture>
    </div>
      <div class="container">
        <div class="advantage__inner pd">
          <div class="advantage__item">
            <div class="advantage__description advantage__description__left">
              <div>
                <h3 class="advantage__description__title">{{$t('main_page.advantages_section.advantages_list[0].name')}}</h3>
              </div>
              <div class="advantage__description__text">{{$t('main_page.advantages_section.advantages_list[0].description')}}</div>
            </div>
          </div>
          <div class="border-left advantage__item">
            <div class="advantage__description">
              <div>
                <h3 class="advantage__description__title">{{$t('main_page.advantages_section.advantages_list[1].name')}}</h3>
              </div>
              <div class="advantage__description__text">{{$t('main_page.advantages_section.advantages_list[1].description')}}</div>
            </div>
            <div class="advantage__description last_item">
              <div>
                <h3 class="advantage__description__title">{{$t('main_page.advantages_section.advantages_list[2].name')}}</h3>
              </div>
              <div class="advantage__description__text">{{$t('main_page.advantages_section.advantages_list[2].description')}}</div>
            </div>
          </div>
        </div>
      </div>

  </section>
</template>

<script>
export default {
  name: "Advantages",
  data() {
    return {
    }
  }
}

</script>

<style lang="scss" scoped>

@import "../base/styles/media";

.advantage{
  overflow: hidden;
}

.advantage__title {
  height: 282px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
  position: relative;

  @media(max-width: 575px){
    align-items: center;
  }


  img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -3;
  }

  @media(max-width: 991px){
    height: 240px;
  }


  @media(max-width: 575px){
    height: 86px;
  }
}

.advantage__title-text {
  font-weight: 700;
  font-size: 48px;
  line-height: 34px;
  color: #fff;

  @media(max-width: 575px){
    font-weight: 700;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    align-self: center;
  }
}



.advantage__item{
  position: relative;
}

.advantage__item:first-child:before{
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% + 304px);
  height: 1px;
  background-color: #F2F2F2;

  @media(max-width: 991px){
    display: none;
  }
}

.advantage__item:first-child{
  display: flex;
  align-items: center;
}

.pd{
  padding-right: 200px;

  @media(max-width: 1199px){
    padding-right: 80px;
  }

  @media(max-width: 767px){
    padding-right: 20px;
  }
}

.advantage__inner {
  display: flex;
  justify-content: space-between;
  min-width: 50%;

  @media(max-width: 991px){
    flex-direction: column;
  }
}

.advantage__description {
  padding-top: 72px;
  padding-bottom: 105px;
  padding-left: 124px;

  @media(max-width: 1399px){
    padding-left: 60px;
  }

  @media(max-width: 991px){
    padding-top: 60px;
    padding-left: 0px;
    padding-bottom: 0px;
    max-width: 100%;
  }

  @media(max-width: 575px){
    padding-top: 30px;
  }

}

.advantage__item{
  flex: 0 0 50%;
}

.advantage__description__left {
  padding-left: 0;
  padding-right: 100px;

  @media(max-width: 1399px){
    padding-right: 60px;
  }

  @media(max-width: 991px){
    padding-right: 0px;
  }

}


.last_item{
  position: relative;
}

.last_item:before {
  content: '';
  display: block;
  width: calc(100% + 515px);
  height: 1px;
  background-color: #F2F2F2;
  position: absolute;
  top: 0;
  left: 0;

  @media(max-width: 991px){
    display: none;
  }
}


.advantage__description__title {
  @extend %subtitle-text;
  font-weight: 700;
}

.advantage__description__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-top: 30px;
  text-transform: none;

  @media(max-width: 991px){
    margin-top: 20px;
    font-size: 16px;
    line-height: 26px;
  }

  @media(max-width: 575px){
    margin-top: 12px;
    font-size: 14px;
    line-height: 24px;
  }
}

.border-left {
  border-left: 1px solid #F2F2F2;

  @media(max-width: 992px){
    border-left: none;
  }
}
</style>
