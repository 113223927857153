<template>
  <section id="sec4">
    <div class="container">
      <div class="project">
        <div class="project__title">
          <div>
            <h2 class="project__title-text">{{ $t('main_page.portfolio_section.title') }}</h2>
          </div>
          <div v-if="this.isPc">
            <div class="project__btn"  v-for="link in recentCases" :key="link.id">
              <ButtonBlack 
                buttonText="main_page.portfolio_section.button"
                :buttonLink="link.path"
                class="project__btn"
              />
            </div>
          </div>
        </div>
        <div class="project__list">
            <RecentProject2
                v-for="project in projectList"
                :key="project.id"
                :projectImageWebp="project.projectImageWebp"
                :projectImagePng="project.projectImagePng"
                :projectTitle="project.projectTitle"
                :projectDescription="project.projectDescription"
                :pathToCase="project.pathToCase"
            />
        </div>
      </div>
      <div v-if="!this.isPc">
        <div class="project__btn" v-for="link in recentCases" :key="link.id">
          <ButtonBlack 
            buttonText="main_page.portfolio_section.button"
            :buttonLink="link.path"
            class="project__btn"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import RecentProject2 from "./RecentProject2";
import ButtonBlack from "../components/buttons/ButtonBlack";

export default {
  name: "RecentProjects2",
  components: {
    RecentProject2,
    ButtonBlack
  },
  data() {
    return {
      isPc: window.matchMedia('(min-width: 992px)').matches,
      projectList: [
        {
          id: 1,
          projectImagePng: require("../assets/ukrpol_image_presentation.png"),
          projectImageWebp: require("../assets/ukrpol_image_presentation.webp"),
          projectTitle: "main_page.portfolio_section.recent_project.ukrpol.projectTitle",
          projectDescription: "main_page.portfolio_section.recent_project.ukrpol.projectDescription",
          pathToCase: '/cases/ukrpol_ua/'
        },
        {
          id: 2,
          projectImagePng: require("../assets/hackenproof_image_presentation.png"),
          projectImageWebp: require("../assets/hackenproof_image_presentation.webp"),
          projectTitle: "cases_page.cases_prevent.hackenproof.projectTitle",
          projectDescription: "cases_page.cases_prevent.hackenproof.projectText",
          pathToCase: '/cases/hackenproof/'
        },
        {
          id: 3,
          projectImagePng: require("../assets/oversecured_image_presentation.png"),
          projectImageWebp: require("../assets/oversecured_image_presentation.webp"),
          projectTitle: "main_page.portfolio_section.recent_project.oversecured.projectTitle",
          projectDescription: "main_page.portfolio_section.recent_project.oversecured.projectDescription",
          pathToCase: '/cases/oversecured/'
        },
      ],
      recentCases: [
        {id: 1, path: '/cases/'}
      ]
    }
    
  },
  methods: {
    setIsPc(){
      this.isPc = window.matchMedia('(min-width: 992px)').matches;
    }
  },
  mounted(){
    window.addEventListener("resize", this.setIsPc);
  },
  unmounted() {
    document.removeEventListener('resize', this.setIsPc);
  },

}
</script>

<style lang="scss" scoped>
@import "../base/styles/fonts.scss";


@media(min-width: 575px){
  .container {
    padding: 0;
  }
}
.project {
  position: relative;
  padding-top: 48px;

  &__title{
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;
  }
  &__list{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 48px;

  @media(max-width: 991px){
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 50px;
  }

  @media(max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media(max-width: 575px){
    margin-bottom: 20px;
    margin-top: 45px;
    row-gap: 60px;
  }
}
&__body {
  &-logo {
    width: 100%;
  }

  &-desc {
    padding: 10px 0 35px;
    font-size: 14px;
    line-height: 22px;
    color: #2E3139
  }

  @media(max-width: 767px){
    flex: 1 1 100%;
  }

  @media(min-width: 768px){
    &:not(:first-child){
      margin-left: 20px;
    }
  }
}
}
</style>
