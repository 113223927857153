<template>
  <a v-if="isExternal" :href="to"><slot/></a>
  <router-link v-else v-bind="$props" :to="to"><slot/></router-link>
</template>

<script>
import {RouterLink} from 'vue-router'

export default {
  name: "Link",
  props:{ ...RouterLink.props },
  computed:{
    isExternal(){
      return typeof this.to === 'string' && (this.to.startsWith('https') || this.to.startsWith('http'))
    }
  }
}
</script>

<style scoped>

</style>
