import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VSupportWebp from "./directives/VSupportWebp";
import VDownloadDesctop from "./directives/VDownloadDesctop";
import VDownloadMobile from "./directives/VDownloadMobile";

import {createI18n} from 'vue-i18n';

import { createHead } from '@vueuse/head';
const head = createHead();

import 'animate.css';

import { languages } from "./locales/global/global-locales";
import { DEFAULT_LOCALE } from "./locales/global/global-locales";
import { FALLBACK_LOCALE } from "./locales/global/global-locales";
const messages = Object.assign(languages)

// console.log(messages, languages);

const i18n = createI18n({
    locale: DEFAULT_LOCALE,
    fallbackLocale: FALLBACK_LOCALE,
    messages
})



createApp(App)
    .use(store)
    .use(router)
    .use(head)
    .directive("support-webp", VSupportWebp)
    .directive("download-desctop", VDownloadDesctop)
    .directive("download-mobile", VDownloadMobile)
    .use(i18n)
    .mount('#app')

