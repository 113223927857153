<template>
  <section id="sec7">
    <div class="contact__follow">
        <div class="container">
          <div>
            <h2 class="contact__title">{{$t("main_page.contact_section.title")}}</h2>
          </div>
        </div>
        <div class="contact__inner">
          <div class="contact">
            <div class="container full-height">
              <div class="contact__form">
                <picture>
                  <source srcset="../assets/mail_ellipse.webp" type="image/webp">
                  <img
                      src="../assets/mail_ellipse.jpg"
                      class="contact__circle"
                      alt=""
                      loading="lazy"
                  />
                </picture>
                <form class="contact__form-email" ref="form" @submit.prevent="sendEmail">
                  <div class="contact__form-item">
                    <input
                        class="contact__form-input form__input"
                        :class="{ 'contact__form-error' : this.errorsInput[0].error}"
                        v-model="name"
                        type="text"
                        name="name"
                        :placeholder="$t('main_page.contact_section.form_name')"
                    >
                    <span
                        v-if="this.errorsInput[0].error"
                    >
                      {{$t('main_page.contact_section.validation_error_name')}}
                    </span>
                  </div>
                  <div class="contact__form-item">
                    <input
                        class="contact__form-input  form__input"
                        :class="{ 'contact__form-error' : this.errorsInput[1].error}"
                        v-model="email"
                        type="email"
                        name="email"
                        :placeholder="$t('main_page.contact_section.form_email')"
                    >
                    <span
                        v-if="this.errorsInput[1].error"
                    >
                      {{$t('main_page.contact_section.validation_error_email')}}
                    </span>
                  </div>
                  <div class="contact__form-item">
                    <input
                        class="contact__form-input  form__input"
                        :class="{ 'contact__form-error' : this.errorsInput[2].error}"
                        v-model="comment"
                        type="text"
                        name="comment"
                        :placeholder="$t('main_page.contact_section.form_comment')"
                    >
                    <span
                        v-if="this.errorsInput[2].error"
                    >
                      {{$t('main_page.contact_section.validation_error_comment')}}
                    </span>
                  </div>
                  <div class="contact-us__policy">
                    <label>
                      <input type="checkbox" name="agree" v-model="agree_checkbox">
                      <span class="contact-us__policy-checkbox"></span>
                      <div>
                        {{$t('contacts_page.form_agree')}}
                      </div>
                    </label>
                  </div>
                  <div class="contact-us__policy">
                    <label>
                      <input type="checkbox" name="would" v-model="would_checkbox">
                      <span class="contact-us__policy-checkbox"></span>
                      <div>{{$t('contacts_page.form_send_letter')}}</div>
                    </label>
                  </div>
                  <div class="contact__form-info">{{$t('main_page.required_fields')}}</div>
                  <input class="contact__form-submit" type="submit" :value="$t('main_page.contact_section.button')">
                </form>

                <div class="contact__form-filter"></div>
              </div>
            </div>
          </div>
          <div class="container w">
            <div class="follows">
              <div class="follows__title">
                <picture>
                  <source srcset="../assets/mail_title_bg.webp" type="image/webp">
                  <img
                      src="../assets/mail_title_bg.png"
                      class="follows__title-blur"
                      alt=""
                      loading="lazy"
                  />
                </picture>
               {{$t('main_page.contact_section.subtitle')}}
              </div>
              <ul class="follows__list">
                <li>{{$t('main_page.contact_section.contact_list[0]')}}</li>
                <li>{{$t('main_page.contact_section.contact_list[1]')}}</li>
                <li>{{$t('main_page.contact_section.contact_list[2]')}}</li>
              </ul>

              <div class="follows__nda">{{$t('main_page.contact_section.nda')}}</div>

              <picture>
                <source
                    srcset="../assets/../assets/follow_bg_desc.webp"
                    type="image/webp"
                >
                <img
                    class="follows__blur"
                    src="../assets/../assets/follow_bg_desc.png"
                    alt=""
                    loading="lazy"
                />
              </picture>

            </div>
          </div>
        </div>
    </div>
  </section>
</template>

<script>
import emailjs from 'emailjs-com';


export default {
  name: "Contact",
  data() {
    return {
      name: '',
      email: '',
      agree_checkbox: false,
      would_checkbox: false,
      comment: '',
      errorsInput: [
        {error: ""},
        {error: ""},
        {error: ""}
      ],
    }
  },
  methods: {
    sendEmail(e){
      this.validateName();
      this.validateEmail();
      this.validateComment();

      if(!this.errorsInput[0].error && !this.errorsInput[1].error && !this.errorsInput[2].error){
        emailjs.sendForm(
            'service_u4lvihc',
            'template_zhu67ce',
            e.target,
            'OGOmyZess3dbFvuxM'
        )
            .then((result) => {
              console.log('SUCCESS!', result.text);
            }, (error) => {
              console.log('FAILED...', error.text);
            });

        this.name = ''
        this.email = ''
        this.comment = ''
        this.agree_checkbox = false
        this.would_checkbox = false
      }
    },

    validateName(){
      this.name = this.name.trim();
      if(!this.name){
        this.errorsInput[0].error = "Please type name";
      } else{
        this.errorsInput[0].error = "";
      }
    },

    validateEmail(){
      this.email = this.email.trim();
      if(!this.email){
        this.errorsInput[1].error = "Please type email";
      } else{
        this.errorsInput[1].error = "";
      }
    },
    validateComment(){
      this.comment = this.comment.trim();
      if (this.comment.length <= 24) {
        this.errorsInput[2].error = "Please type comment";
      } else {
        this.errorsInput[2].error = "";
      }
    },
  }
}
</script>

<style lang="scss">
.contact__follow {
  padding-top: 96px;
  overflow: hidden;

  @media(max-width: 575px){
    padding-top: 117px;
  }
}

.contact__inner{
  @media(min-width: 992px){
    display: flex;
    justify-content: space-between;
  }
}

.contact{
  flex: 0 0 100%;
}

.w{
  flex: 0 0 50%;
}

.contact-us__policy {
  display: flex;
  align-items: flex-start;
  padding-left: 39px;
  margin-top: 24px;
  max-width: 350px;

  @media(max-width: 991px){
    max-width: 498px;
  }

  label{
    display: flex;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #4F4F4F;
  }

  input{
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
  }

  span{
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #688E93;
    border-radius: 1px;
    margin-left: -39px;
    margin-top: 2px;
  }

  input:checked + span{
    background-image: url("../assets/form_tick.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    background-color: #688E93;
  }

  &-link{
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #688E93 !important;
    text-decoration: underline;
  }

}

.contact__form {
  height: 100%;
  padding: 69px 168px 69px 105px;
  position: relative;

  @media(max-width: 1399px){
    padding: 69px 120px 69px 80px;
  }

  @media(max-width: 1199px){
    padding: 69px 30px 69px 60px;
  }

  @media(max-width: 991px){
    padding: 69px 40px 69px 40px;
  }

  @media(min-width: 992px){
    width: 50%;
  }

  @media(max-width: 575px){
    margin-left: -12px;
    margin-right: -12px;
    padding: 36px 40px 36px 40px;

  }

  @media(max-width: 460px){
    padding: 36px 15px 36px 15px;
  }


  &-filter{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;

    @supports (backdrop-filter: blur(30px)) {
      & {
        backdrop-filter: blur(30px);
        -webkit-filter: blur(20px);
        -moz-filter: blur(30px);
        -o-filter: blur(30px);
        -ms-filter: blur(30px);
        filter: blur(30px);
      }
    }

    @supports not (backdrop-filter: blur(20px)){
      & {
        background-image: url("../assets/mail_bg_full.png");
        background-size: cover;

        @media(max-width: 575px){
          background-image: url("../assets/mail_bg_mobile.png");
        }
      }
    }
  }

  &-email {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-item{
    display: flex;
    flex-direction: column;
    position: relative;

    &:not(:first-child){
      margin-top: 30px;
    }

    @media(max-width: 575px){
      &:not(:first-child){
        margin-top: 24px;
      }
    }

    span{
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #D93737;
    }
  }

  &-error{
    border-bottom: 1px solid #D93737 !important;
  }

  &-cancel{
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 10;
    cursor: pointer;
  }


  &-submit {
    height: 60px;
    background: #2E3139;
    border: 1px solid #2E3139;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 1px;
    color: #FFFFFF;
  }

  &-info {
    font-size: 14px;
    line-height: 22px;
    color: #2E3139;
    margin-top: 20px;
    text-align: right;
    margin-bottom: 24px;

    @media(max-width: 575px){
      text-align: center;
      margin-bottom: 24px;
    }
  }
}

.contact__circle{
  display: block;
  width: 350px;
  height: 350px;
  position: absolute;
  bottom: 50px;
  left: -100px;
  z-index: -2;

  @media(max-width: 575px) {
    top: -120px;
    left: 50%;
    width: 220px;
    height: 220px;
    transform: rotate(-45deg);
  }
}

.form__input {
  font-size: 18px;
  line-height: 28px;
  color: #83898B;
  border: none;
  border-bottom: 1px solid #83898B;
  background: inherit;
  outline: none;

  @media(max-width: 991px){
    font-size: 16px;
    line-height: 26px;
  }

  @media(max-width: 575px){
    font-size: 14px;
    line-height: 24px;
  }
}

.full-height{
  height: 100%;
}

.contact__title {
  font-weight: bold;
  font-size: 96px;
  line-height: 80px;

  @media(max-width: 991px) {
    font-size: 72px;
    line-height: 54px;
  }

  @media(max-width: 575px){
    font-size: 36px;
    line-height: 27px;
    margin-left: 25px;
  }

  @media(max-width: 460px){
    margin-left: 0px;
  }
}

.follows {
  color: #fff;
  padding: 132px 50px 100px 52px;
  position: relative;

  @media(max-width: 1199px){
    padding: 90px 40px 100px 40px;
  }

  @media(min-width: 992px){
    transform: translateX(-100%);
  }

  @media(max-width: 575px){
    margin-left: -12px;
    margin-right: -12px;
    padding: 34px 40px 57px 40px;
  }

  @media(max-width: 460px){
    padding: 34px 15px 57px 15px;
  }

  &__blur{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -3;
  }
}

.follows__title {
  font-size: 48px;
  line-height: 60px;
  font-weight: 700;
  position: relative;
  z-index: -2;

  @media(max-width: 991px){
    font-size: 42px;
    line-height: 52px;
  }

  @media(max-width: 575px){
    font-size: 18px;
    line-height: 20px;
  }

  &-blur{
    display: block;
    width: 570px;
    max-width: 100%;
    height: 62px;
    position: absolute;
    bottom: 12px;

    left: -52px;
    z-index: -3;

    @media(max-width: 1199px) {
      left: -40px;
    }

    @media(max-width: 575px){
      height: 50px;
      bottom: 4px;
    }


  }
}

.follows__list > li {
  list-style: symbols;
}

.follows__list {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-top: 30px;
  margin-left: 22px;

  @media(max-width: 991px){
    font-size: 16px;
    line-height: 26px;
    margin-top: 50px;
  }

  @media(max-width: 575px){
    font-size: 14px;
    line-height: 24px;
    margin-top: 30px;
  }
}

.follows__nda {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  max-width: 441px;
  margin-top: 30px;

  @media(max-width: 991px) {
    font-size: 16px;
    line-height: 26px;
    margin-top: 36px;
  }

  @media(max-width: 575px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
  }
}
</style>
