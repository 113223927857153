<template>
  <section class="services" id="sec1">
    <div class="container">
      <div class="services__inner">
        <div class="services__img">
          <div>
            <h2 class="services__title">{{$t('main_page.services_section.title')}}</h2>
          </div>
          <div class="services__description__bg">
            <div class="services__description">
              {{$t('main_page.services_section.text')}}
            </div>
          </div>
          <div class="services__bg">
            <picture>
              <source
                  srcset="../assets/service_bg_full.webp"
                  type="image/webp"
              >
              <img
                  src="../assets/service_bg_full.png"
                  alt=""
                  loading="lazy"
              />
            </picture>
          </div>
        </div>

        <div class="services__main">
          <div class="services__description__item" v-for="(service, index) in $tm('main_page.services_section.services_list')" :key="index">
            <div class="services__description__title">
              <h3 v-html=" $rt(service.name)"></h3>
              <div class="services__description__text">{{ $rt(service.description) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Services",
  data() {
    return {

    }
  }
}
</script>

<style lang="scss">

@import "../base/styles/media";

@media(max-width: 991px){
  .services{
    padding-top: 70px;
  }
}

.services__img{
 // flex: 0 0 580px;
  flex: 0 0 460px;
  min-height: 798px;
  max-height: 798px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media(max-width: 1399px){
    flex: 0 0 500px;
  }

  @media(max-width: 1199px){
    flex: 0 0 400px;
  }


  @media(max-width: 991px) {
    flex: 0 1 700px;
    min-height: 528px;
  }


  @media(max-width: 575px) {
    min-height: 354px;
    padding-left: 33px;
    padding-top: 30px;
    padding-bottom: 46px;
    justify-content: space-between;
  }

}

.services__bg{
  width: 884px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0px;


    @media(max-width: 1399px){
      object-position: 120px;
    }

    @media(max-width: 1199px){
      object-position: 100px;
    }
    @media(max-width: 991px){
      object-position: 0px;
    }

    @media(max-width: 575px){
      object-position: 100px 0px;
    }
  }

  @media(max-width: 575px) {
    right: 45px;
  }
}

.services__inner{
  display: flex;

  @media(max-width: 991px){
    flex-wrap: wrap;
  }
}

.services__main {
  display: grid;
  grid-template-columns: (1fr 1fr);
  row-gap: 56px;
  column-gap: 45px;
  margin-left: 104px;
  margin-top: 90px;

  @media(max-width: 1399px){
    margin-left: 20px;
  }

  @media (max-width: 991px) {
    display: block;
    margin-left: 0px;
  }

  @media (max-width: 575px) {
    display: block;
    padding-right: 45px;
  }

}

.services__title {
  font-size: 64px;
  line-height: 48px;
  font-weight: 700;
  color: #fff;
  text-transform: capitalize;
  max-width: 345px;

  @media(max-width: 991px){
    font-size: 54px;
    line-height: 39px;
  }

  @media(max-width: 575px){
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 24px;
    margin-left: 30px;
  }
}

.services__description {
  font-weight: 400;
  @extend %secondary-text;
  color: #fff;
  position: relative;
  padding: 107px 190px 88px 0px;

  @media(max-width: 1399px){
    padding: 90px 100px 88px 0px;
  }

  @media(max-width: 1199px){
    padding: 80px 50px 88px 0px;
  }

  &:before{
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 884px;
    height: 100%;
    @supports (backdrop-filter: blur(30px)) {
      & {
        backdrop-filter: blur(100px);
        -webkit-filter: blur(100px);
        -moz-filter: blur(100px);
        -o-filter: blur(100px);
        -ms-filter: blur(10px);
        filter: blur(100px);
      }
    }

    @supports not (backdrop-filter: blur(30px)){
      & {
        background: url("../assets/service-desc-bg.png") no-repeat;
        background-size: cover;
      }
    }

    @media(max-width: 575px){
      width: calc(100% + 12px);
      right: -12px;
    }
  }

  @media(max-width: 575px){
    padding: 30px 90px 64px 29px;
  }

}

.services__description__item {
  @media(min-width: 992px){
    width: 100%;
    max-width: 314px;
    //margin-left: 53px;
  }
}

.services__description__item:first-child > .services__description__title{
  @media(max-width: 991px) {
    margin-top: 45px;
  }
}

.services__description__title {
  @extend %subtitle-text;
  font-weight: 700;
  color: #2E3139;
  //margin-top: 90px;

  @media(max-width: 991px) {
    margin-top: 30px;
  }



}

.services__description__text {
  font-size: 14px;
  line-height: 22px;
  text-transform: none;
  font-weight: 400;
  margin-top: 10px;
  max-width: 314px;

  @media(max-width: 991px) {
    margin-top: 10px;
    max-width: 100%;
  }

  @media(max-width: 575px){
    margin-top: 10px;
  }
}

</style>
