<template>
    <router-link class="button-white" aria-current="page" :to="`${buttonLink}`"> {{$t(buttonText)}}
        <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30.3536 4.35355C30.5488 4.15829 30.5488 3.84171 30.3536 3.64645L27.1716 0.464466C26.9763 0.269204 26.6597 0.269204 26.4645 0.464466C26.2692 0.659728 26.2692 0.976311 26.4645 1.17157L29.2929 4L26.4645 6.82843C26.2692 7.02369 26.2692 7.34027 26.4645 7.53553C26.6597 7.7308 26.9763 7.7308 27.1716 7.53553L30.3536 4.35355ZM0 4.5H30V3.5H0V4.5Z" fill="white"/>
        </svg>
    </router-link>
</template>

<script >

export default {
  name: "ButtonWhite2",
  props: {
    buttonText: {
      type: String,
    },
    buttonLink:{
      type: String,
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import "../../base/styles/global.css";


.button-white{
    border: solid 2px #fff;
    color: #fff !important;
    padding: 8px 20px;
    width: fit-content;
    transition: 0.4s all;
    path { 
      fill: #fff; 
      transition: 0.4s all;
    }
    svg{
        margin-left: 20px;
    }
    @media(min-width: 991px){
      &:hover{
        color: #000 !important;
        background: #fff;
        path { fill: #000; }
      }
    }
}

</style>