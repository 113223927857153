<template>
  <section class="partner" id="sec2">
    <div class="container">
      <h2 class="partner-info__title">{{$t('main_page.partners_and_clients_section.title')}}</h2>
    </div>
    <div class="partner-info__items">
      <div class="partner-info__list">
          <div class="partner-info__item"><img src="../assets/icon-1.svg" alt="" class="partner-info__img"></div>
          <div class="partner-info__item"><img src="../assets/icon-2.svg" alt="" class="partner-info__img"></div>
          <div class="partner-info__item"><img src="../assets/icon-3.svg" alt="" class="partner-info__img"></div>
          <div class="partner-info__item"><img src="../assets/icon-7.svg" alt="" class="partner-info__img"></div>
          <div class="partner-info__item"><img src="../assets/icon-4.svg" alt="" class="partner-info__img"></div>
          <div class="partner-info__item"><img src="../assets/icon-5.svg" alt="" class="partner-info__img"></div>
          <div class="partner-info__item"><img src="../assets/icon-6.svg" alt="" class="partner-info__img"></div>
          <div class="partner-info__item"><img src="../assets/icon-8.svg" alt="" class="partner-info__img"></div>
          <div class="partner-info__bg">
            <picture>
              <source srcset="../assets/service-desc-bg.webp" type="image/webp">
              <img
                  src="../assets/service-desc-bg.png"
                  class="faq__circle"
                  alt=""
                  loading="lazy"
              />
            </picture>
          </div>
      </div>

      <picture>
          <source
              srcset="../assets/partner-bg.webp"
              type="image/webp"
          >
          <img
              class="partner-info__image"
              src="../assets/partner-bg.jpg"
              alt=""
              loading="lazy"
          />
      </picture>

    </div>
  </section>
</template>

<script>
export default {
  name: "PartnerAndClient"
}
</script>

<style lang="scss">

@import "../base/styles/media";

.partner{
  padding-top: 60px;

  @media(max-width: 991px){
    padding-top: 52px;
  }

  @media(max-width: 575px){
    padding-top: 45px;
  }
}

.partner-info__items {
  min-height: 480px;
  position: relative;
  z-index: -1;

  .partner-info__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -3;
  }
}



.partner-info__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  align-items: center;
  position: relative;
  padding: 140px 80px 80px 80px;
  margin-left: 300px;
  top: -50px;

  img{
    max-width: 100%;
    object-fit: cover;
  }

  @media(max-width: 1399px){
    padding: 95px 50px 30px 50px;
  }


  @media(max-width: 1199px){
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
    margin-left: 150px;
  }

  @media(max-width: 767px){
    grid-template-columns: repeat(2, 1fr);
    margin-left: 100px;
    padding: 95px 50px 30px 90px;
  }

  @media(max-width: 700px){
    padding: 95px 50px 30px 50px;
  }

  @media(max-width: 575px){
    padding: 56px 29px 10px 29px;
  }

  @media(max-width: 575px){
    margin-left: 45px;
  }
}

.partner-info__bg{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;

  img{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @supports (backdrop-filter: blur(30px)) {
    & {
      backdrop-filter: blur(30px);
      -webkit-filter: blur(30px);
      -moz-filter: blur(30px);
      -o-filter: blur(30px);
      -ms-filter: blur(30px);
      filter: blur(30px);
    }
  }

  @supports not (backdrop-filter: blur(30px)){
    & img{
      display: block;
    }
  }
}

.partner-info__title {
  font-size: 64px;
  line-height: 66px;
  font-weight: 700;
  color: #2E3139;
  max-width: 100%;
  margin-bottom: 96px;

  @media(max-width: 991px){
    font-size: 48px;
    line-height: 66px;
  }

  @media(max-width: 575px){
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 86px;
  }
}

.partner-info__item {
  max-width: 286px;
  min-height: 75px;
  padding-bottom: 85px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  @media(min-width: 768px){
    justify-content: center;
  }

  @media(max-width: 575px){
    padding-bottom: 60px;
  }

  @media(max-width: 767px){
    &:nth-child(7){
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &:nth-child(8){
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &:nth-child(5){
      grid-row-start: 3;
      grid-row-end: 4;
    }

    &:nth-child(6){
      grid-row-start: 3;
      grid-row-end: 4;
    }
  }
}
</style>
