<template>
    <section id="sec5">
      <div class="container">
        <div class="social">
          <div class="social__main">
            <div>
              <h2 class="social__title">{{$t('main_page.social_and_work_section.title')}}</h2>
            </div>
            <div class="social__text">
              {{$t('main_page.social_and_work_section.first_text')}}
            </div>
            <div class="social__text last__item">
              {{$t('main_page.social_and_work_section.second_text')}}
            </div>

            <div class="social__images-mobile">
              <a :href="link.href" v-for="link in socialWorkLinks" :key="link.id">
                <img :src="link.image" alt="">
              </a>
            </div>

            <div v-for="(link, index) in workApproach" :key="index">
              <a @click="goToPage(link.path)" class="social__btn btn__general btn__general-black" aria-current="page"> {{$t('main_page.social_and_work_section.button')}}
                <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30.3536 4.35355C30.5488 4.15829 30.5488 3.84171 30.3536 3.64645L27.1716 0.464466C26.9763 0.269204 26.6597 0.269204 26.4645 0.464466C26.2692 0.659728 26.2692 0.976311 26.4645 1.17157L29.2929 4L26.4645 6.82843C26.2692 7.02369 26.2692 7.34027 26.4645 7.53553C26.6597 7.7308 26.9763 7.7308 27.1716 7.53553L30.3536 4.35355ZM0 4.5H30V3.5H0V4.5Z" fill="#2E3139"/>
                </svg>
              </a>
            </div>
          </div>
          <div class="social__images">
            <a :href="link.href" v-for="link in socialWorkLinks" :key="link.id">
              <img :src="link.image" alt="">
            </a>
          </div>
        </div>
      </div>
    </section>
</template>

<script>


export default {
  name: "SocialAndWork",
  data() {
    return {
      workApproach: [
        {id: 1, name: 'process of work', path: '/work-approach/'}
      ],
      socialWorkLinks: [
        {id: 1, href: "https://designvillage.world/", image: require("../assets/approach_img_1.png")},
        {id: 2, href: "https://it-cluster.if.ua/", image: require("../assets/approach_img_2.png")}
      ]
    }
  },
  methods: {
    goToPage(path){
      this.$router.push(path);
    }
  }
}
</script>

<style lang="scss">
@import "../base/styles/media";

.social {
  padding-top: 124px;
  display: flex;
  justify-content: space-between;

  @media(max-width: 991px){
    padding-top: 90px;
  }

  @media(max-width: 575px){
    padding-top: 60px;
  }
}

@media(min-width: 992px){
  .social__main{
    max-width: 603px;
  }
}

.social{
  &__title{
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    color: #2E3139;

    @media(max-width: 991px){
      font-size: 36px;
      line-height: 48px;
      max-width: 600px;
    }

    @media(max-width: 575px){
      font-size: 24px;
      line-height: 36px;
    }
  }
}


.social__btn {
  margin-top: 54px;
  font-weight: bold !important;
  font-size: 18px;
  line-height: 28px;
}

.last__item{
  margin-top: 24px;

  @media(max-width: 991px){
    margin-top: 22px;
  }

  @media(max-width: 575px){
    margin-top: 20px;
  }
}

.social__text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #2E3139;
  max-width: 858px;
}

.social__images-mobile{
  margin-top: 40px;
  a:last-child{
    margin-left: 108px;
  }
  @media(min-width: 992px)  {
    display: none;
  }

  @media(max-width: 380px){
    display: flex;
    justify-content: space-between;
  }
}

.social__images{
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
  a:last-child{
    margin-left: 108px;
  }

  @media(max-width: 991px){
    display: none;
  }
}
</style>
