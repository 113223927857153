import en from '../en.json';
import ua from '../ua.json';

export const DEFAULT_LOCALE = 'en'
export const FALLBACK_LOCALE = 'ua'

export const languages = {
    en: en,
    ua: ua
}
