<template>
  <router-view>
    <div class="header_dark" :class="{'header-dark_fixed': this.isHeaderScrolled}">
      <div ref="nav-header_bg" class="header__bg"></div>
      <nav class="nav-container">
        <div class="container">
          <nav>
            <div class="flex justify-between align-center">
              <div class="navbar-logo">
                <img class="logo" src="../assets/logo_dark.svg" alt="logo webmil" @click="$router.push('/')">
                <div class="internationalization_mobile flex align-center">
                  <button :class="{'internationalization-button__active': this.$root.$i18n.locale === button.name}" v-for="(button) in internationalizationButtons" :key="button.id" @click="setWebsiteLanguage(button.name)" :ref="'internationalization-button' + button.id">
                    {{button.name.toUpperCase()}}
                  </button>
                </div>
              </div>
              <div @click="openMenu()" class="navbar-burger" id="dropdownMenuIcon-js">
                <div class="dropdown-menu_icon dropdown-menu_icon-black" id="openIcon">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>

              <div class="dropdown-menu_list dropdown-menu_list-light flex" id="dropdownMenuList-js">
                <ul>
                  <li class="nav-item" v-for="(menu, index) in menuHeaderList" :key='index'>
                    <a @click="goToPage(menu.path)" class="navbar-link" aria-current="page">{{ $t(menu.name) }}</a>
                  </li>
                  <li>
                    <Link class="navbar-link" :to="'https://blog.webmil.eu/'">{{ $t('main_page.header.nav[2]') }}</Link>
                  </li>
                </ul>

                <div v-for="(project, i) in cases" :key="i">
                  <a @click="goToPage(project.path)" class="nav__btn btn__general btn__general-black" aria-current="page">{{ $t(project.name) }}
                    <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M30.3536 4.35355C30.5488 4.15829 30.5488 3.84171 30.3536 3.64645L27.1716 0.464466C26.9763 0.269204 26.6597 0.269204 26.4645 0.464466C26.2692 0.659728 26.2692 0.976311 26.4645 1.17157L29.2929 4L26.4645 6.82843C26.2692 7.02369 26.2692 7.34027 26.4645 7.53553C26.6597 7.7308 26.9763 7.7308 27.1716 7.53553L30.3536 4.35355ZM0 4.5H30V3.5H0V4.5Z" fill="#2E3139"/>
                    </svg>
                  </a>
                </div>
                <div class="internationalization flex align-center">
                  <button :class="{'internationalization-button__active': this.$root.$i18n.locale === button.name}" v-for="(button) in internationalizationButtons" :key="button.id" @click="setWebsiteLanguage(button.name)" :ref="'internationalization-button' + button.id">
                    {{button.name.toUpperCase()}}
                  </button>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </nav>
    </div>
  </router-view>
</template>

<script>

import Link from "./Link";

export default {
  data() {
    return {
      menuHeaderList: [
        {name: 'main_page.header.nav[0]', path: '/cases/'},
        {name: 'main_page.header.nav[1]', path: '/work-approach/'}
      ],
      cases: [
        {name: 'main_page.header.button', path: '/contacts/'}
      ],
      internationalizationButtons: [
        {id: 1, name: 'en'},
        {id: 2, name: 'ua'}
      ],
      isHeaderScrolled: false
    }
  },
  components: {
     Link
  },
  methods: {
    openMenu() {
      let menuList = document.getElementById('dropdownMenuList-js')
      let openIcon = document.getElementById('openIcon')
      let body = document.querySelector("body");
      menuList.classList.toggle('open')
      openIcon.classList.toggle('icon-trigger')
      body.classList.toggle("lock")
    },
    goToPage(path){
      this.$router.push(path);
      let body = document.querySelector("body");
      let menuList = document.getElementById('dropdownMenuList-js')
      let openIcon = document.getElementById('openIcon')
      if(body.classList.contains("lock")){
        menuList.classList.remove('open')
        openIcon.classList.remove('icon-trigger')
        body.classList.remove("lock");
      }
    },
    reloadPage() {
      window.location.reload();
    },
    checkHeaderPosition(){
      if(!this.$refs["nav-header_bg"]){
        return false
      }

      if(window.scrollY > 0 && window.scrollY <= 100){
        this.isHeaderScrolled = true
        this.$refs["nav-header_bg"].style.opacity = "0.7"
      } else if (window.scrollY > 100 && window.scrollY <= 200) {
        this.isHeaderScrolled = true
        this.$refs["nav-header_bg"].style.opacity = "0.9"
      } else if (window.scrollY > 200){
        this.isHeaderScrolled = true
        this.$refs["nav-header_bg"].style.opacity = "1"
      } else {
        this.isHeaderScrolled = false
      }
    },
    setWebsiteLanguage(language){
      this.$root.$i18n.locale = language;
    }
  },
  mounted(){
    window.addEventListener("scroll", this.checkHeaderPosition);
  }
}
</script>

<style lang="scss">
@import "../base/styles/global";

.header_dark{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  .internationalization{
    .internationalization-button__active{
      font-weight: 700;
      color: #2E3139;
    }
    button{
      transition: 0.1s all;
      color: #83898B;
    }
  }

  .internationalization_mobile{
    .internationalization-button__active{
      font-weight: 700;
      color: #2E3139;
    }
    margin-left: 20px;
    button{
      transition: 0.1s all;
      color: #83898B;
      font-weight: 400;
    }
  }
}

.header-dark_fixed{
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 0.7s all;

  .header__bg{
    background: #fff;
  }

  .nav-container{
    padding: 27px 0px;

    @media (max-width: 991px) {
      padding: 20px 0px;
    }

    @media(max-width: 575px){
      padding: 16px 0px;
    }
  }
}


.nav-container{
  padding: 27px 0px;

  @media (max-width: 991px) {
    padding: 20px 0px;
  }

  @media(max-width: 575px){
    padding: 16px 0px;
  }
}

.navbar-logo{
  cursor: pointer;
  display: flex;
  align-items: center;
}

.ml-auto {
  margin-left: auto;
}

.navbar-collapse {
  @media (max-width: 768px) {
    background-color: black !important;
  }
}

.navbar-toggler {
  border: none !important;
  background-color: #191A1B;
}

a {
  font-size: 20px;
  line-height: 28px;
  color: #000;
}

.btn-contact {
  margin-left: 120px;
  border: 1px solid #fff;
  padding: 8px 70px 8px 20px;
}

.dropdown-menu_icon-black {
  span{
    @media (max-width: 991px) {
      background: black;
    }
  }
}

@media(max-width: 991px){
  .dropdown-menu_list-light{
    background-color: #fff;
    backdrop-filter: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    ul{
    //  display: flex;
    //  flex-direction: column;
      //justify-content: center;

      li:not(:first-child){
        margin-top: 42px;
      }
    }
  }

  .open{
    left: 0;
    padding-bottom: 100px;
  }

  .logo {
    position: relative;
    z-index: 1;
  }
}
@media (min-width: 430px),
       (min-height: 895px){
  .open{
    padding-bottom: 150px;
  }
}
@media (min-width: 768px),
       (min-height: 1368px){
  .open{
    padding-bottom: 250px;
  }
}


</style>

