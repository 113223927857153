<template>
  <section class="promo-video">
    <div class="container h container__video-sec">
      <div class="description__title">
        <h1>
          {{$t('main_page.main_screen_video_section.title')}}
        </h1>
      </div>
      <div class="description__title-text">
        {{$t('main_page.main_screen_video_section.text')}}
      </div>

      <div class="description__label">
        <div class="description__link" v-for="link in videoCases" :key="link.id">
          <router-link class="description__btn-link btn__general" aria-current="page" :to="link.path"> {{$t('main_page.main_screen_video_section.button')}}
            <svg width="31" height="8" viewBox="0 0 31 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M30.3536 4.35355C30.5488 4.15829 30.5488 3.84171 30.3536 3.64645L27.1716 0.464466C26.9763 0.269204 26.6597 0.269204 26.4645 0.464466C26.2692 0.659728 26.2692 0.976311 26.4645 1.17157L29.2929 4L26.4645 6.82843C26.2692 7.02369 26.2692 7.34027 26.4645 7.53553C26.6597 7.7308 26.9763 7.7308 27.1716 7.53553L30.3536 4.35355ZM0 4.5H30V3.5H0V4.5Z" fill="white"/>
            </svg>
          </router-link>
        </div>
        <div @click="moveToSection()" :class="{'policy-unvisible': this.isAcceptedPolicy}" class="description__label-arrow animate__animated animate__fadeInDown animate__infinite animate__delay-1s animate__slow">
          <img src="../assets/arrow.svg" alt="">
        </div>
      </div>
      <div class="webflow__container">
        <div class="webflow__container-title">
          <img src="../assets/pages/webflow/Webflow_logo_2023.svg" alt="" loading="lazy" />
        </div>
        <p class="webflow__container-description">{{$t('main_page.main_screen_video_section.webflow.description')}}</p>
        <router-link to="/webflow" aria-current="page" class="webflow__container-link">{{$t('main_page.main_screen_video_section.webflow.button')}}</router-link>
      </div>
      <router-link to="/webflow" aria-current="page" class="webflow__container__mobile">
        <img src="../assets/pages/webflow/Webflow_logo.svg" alt="" loading="lazy" />
        <p>Webflow</p>
      </router-link>
    </div>
    <video autoplay muted loop class="video-mob">
      <source v-download-mobile srcset="../assets/video/webmil_mobile.mp4" type="video/mp4">
      <source v-download-mobile srcset="../assets/video/webmil_mobile.webm"  type="video/webm">
    </video>
    <video autoplay muted loop class="video-desktop">
      <source v-download-desctop srcset="../assets/video/webmil_web.mp4" type="video/mp4">
      <source v-download-desctop srcset="../assets/video/webmil_web.webm" type="video/webm">
    </video>
  </section>
</template>

<script>
export default {
  name: "MainScreenVideo",
  data() {
    return {
      videoCases: [
        {id: 1, name: 'View cases', path: '/cases/'}
      ],
      isAcceptedPolicy: null
    }
  },
  methods: {

    moveToSection(){
      window.scrollTo({
        top: document.querySelector(".promo-video").scrollHeight,
        behavior: "smooth"
      })

      this.makeAnchorsVisible()
    },
    makeAnchorsVisible(){
      this.$emit('makeAnchorsVisible');
    }
  },
}
</script>

<style lang="scss">
@import "../base/styles/global";

.webflow__container{
  position: absolute;
  bottom: 129px;
  right: 0;
  background: #fff;
  width: 100%;
  max-width: 358px;
  padding: 24px;
  box-shadow: -10px 10px 0px 0px #146EF5;
  font-size: 18px;
  line-height: 28px;
  color: #2E3139;
  @media(max-width: 575px){
    display: none;
    position: absolute;
    bottom: 129px;
    right: 0;
  }
  &-description{
    margin-top: 20px;
    margin-bottom: 4px;
  }
  &-link{
    font-weight: 700;
    text-decoration: underline;
  }
  
}
.webflow__container__mobile{
  display: none;
  @media(max-width: 575px){
   display: flex;
   justify-content: flex-end;
   color: #fff;
   margin-bottom: 10px;
   img{
      width: 10%;
      margin-right: 5px;
    }
  }
}
.container__video-sec{
  position: relative;
}

.policy-enter-active,
.policy-leave-active {
  transition: opacity 0.2s ease-in-out;
}

.policy-enter-from,
.policy-leave-to {
  opacity: 0;
}

.description__title, .description__title-text, .description__label {
  color: white;
}

.description__title {
  letter-spacing: 2px;
  max-width: 840px;
  padding-top: 240px;

  @media(max-width: 767px){
    padding-top: 136px;
  }

  @media(max-width: 575px){
    padding-top: 140px;
    padding-right: 74px;
  }

  h1{
    font-weight: bold;
    font-size: 72px;
    line-height: 72px;
    color: #FFFFFF;

    @media (max-width: 991px) {
      font-weight: 900;
      font-size: 48px;
      line-height: 55px;
      letter-spacing: 2px;
      max-width: 600px;
    }

    @media (max-width: 767px) {
      font-weight: 900;
      font-size: 48px;
      line-height: 55px;
      letter-spacing: 2px;
      max-width: 600px;
    }

    @media (max-width: 767px) {
      font-weight: 900;
      font-size: 48px;
      line-height: 55px;
      letter-spacing: 2px;
      max-width: 600px;
    }


    @media (max-width: 575px) {
      font-weight: 900;
      font-size: 36px;
      line-height: 42px;
    }
  }
}

.description__title-text {
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  margin-top: 30px;
  max-width: 600px;
  margin-bottom: 60px;
  @media (max-width: 991px) {
    margin-bottom: 42px;
  }

  @media (max-width: 575px) {
    margin-bottom: 42px;
    padding-right: 74px;
  }
}

@media(max-width: 991px){
  .description__link{
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: flex-end;
  }
}

.description__label{
  display: flex;
  justify-content: space-between;
  min-height: 110px;

  @media(max-width: 991px){
    flex: 1 1 auto;
    padding-bottom: 45px;
  }

  &-inner{
    display: flex;

    @media(max-width: 991px){
      flex-direction: column;
    }
  }

  &-text{
    padding: 18px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;

    @media(max-width: 991px){
      font-size: 16px;
      line-height: 26px;
    }

    @media(max-width: 575px){
      font-size: 14px;
      line-height: 24px;
    }

    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
      width: 100%;
      height: 100%;
      background: #2E3139;
      opacity: 0.75;

      @media (max-width: 991px) {
        opacity: 1;
      }
    }
  }

  &-link{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    border: 1px solid #fff;
    min-width: 105px;
    min-height: 31px;
    border-radius: 10px;
    transition: 0.4s all;
    align-self: center;
    margin-left: 30px;

    @media(max-width: 991px){
      margin-left: 0;
      margin-top: 20px;
    }


    @media (any-hover: hover) {
      &:hover  {
        color: rgba(46, 49, 57, 1) !important;
        background: #fff;
        border: 1px solid #fff;
      }
    }

    &:active{
      color: #fff !important;
      background: transparent;
      border: 2px solid #fff;
    }
  }
}

.description__main-link{
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  transition: 0.4s color;

  @media (max-width: 991px) {
    font-size: 16px;
  }

  @media (max-width: 575px) {
    font-size: 14px;
  }


  @media (any-hover: hover) {
    &:hover  {
      color: #688E93;
    }
  }
}

.description__label-arrow {
  position: absolute;
  bottom: 28px;
  left: 50%;
  z-index: 1;
  display: block;
  width: 20px;
  height: 70px;
  background-image: url("../assets/arrow.svg");
  background-repeat: no-repeat;
  cursor: pointer;

  @media(max-width: 991px) {
    display: none;
  }
}

.description__btn-link {
  min-height: 60px !important;
  min-width: 220px;
  font-size: 24px !important;
  line-height: 36px;
  font-weight: bold !important;
  align-self: flex-start;
  background-color: #fff;
  color: rgba(46, 49, 57, 1) !important;

  svg > path {
    fill: rgba(46, 49, 57, 1);
  }


  &:hover  {
    color: #fff !important;
    background: transparent;
    border: 1px solid #fff;

    svg > path{
      fill: #fff;
    }
  }

  &:active {
    border: 2px solid #fff;
  }
}

video {
  object-fit: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -4;
}

.video-desktop {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  @media (max-width: 991px) {
    display: none;
  }

  &:after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url("../assets/video_bg.png");
    background-size: cover;
    z-index: 1000;
  }
}

.video-mob {
  margin: 0;
  display: none;
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: right;
  @media (max-width: 991px) {
    display: block;
    background: black;
  }
}

.promo-video {
  height: 100vh;
  position: relative;
  overflow: hidden;

  .h {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
</style>
