export default {
    beforeMount(el){
        function testWebP() {
            return new Promise(res => {
                const webP = new Image();
                webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
                webP.onload = webP.onerror = () => {
                    res(webP.height === 2);
                };
            })
        }

        testWebP().then(hasWebP => {
            if(hasWebP) {
                el.classList.add("yes__webp")
            } else {
                el.classList.add("no__webp")
            }
        });
    }
}
