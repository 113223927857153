<template>
  <div id="app">
    <div>
      <Header v-if="$route.name === 'Main'" :currentLanguage="this.currentLanguage"/>
      <HeaderDark
          v-if="$route.name !== 'Main' &&
          $route.name !== 'NotFound' &&
          $route.name !== 'SeeYouSoon' &&
          $route.name !== 'karpaty_ua' &&
          $route.name !== 'architectural-company-archimatika' &&
          // $route.name !== 'bigboard' &&
          $route.name !== 'big_idea' &&
          // $route.name !== 'hackenproof' &&
          $route.name !== 'residential-area-manhattan' &&
          // $route.name !== 'prykarpatty-oblenergo' &&
          $route.name !== 'uis' &&
          $route.name !== 'construction-company-vertical' &&
          $route.name !== 'teple-misto'"
          :currentLanguage="this.currentLanguage"
      />
      <router-view />
      <foo-bar
          v-if="$route.name !=='NotFound' &&
          $route.name !== 'SeeYouSoon' &&
          $route.name !== 'karpaty_ua' &&
          $route.name !== 'architectural-company-archimatika' &&
          // $route.name !== 'bigboard' &&
          $route.name !== 'big_idea' &&
          // $route.name !== 'hackenproof' &&
          $route.name !== 'residential-area-manhattan' &&
          // $route.name !== 'prykarpatty-oblenergo' &&
          $route.name !== 'uis' &&
          $route.name !== 'construction-company-vertical' &&
          $route.name !== 'teple-misto'"
      />

    </div>
  </div>
</template>

<script>
import Header from '@/components/HeaderLight.vue';
import HeaderDark from "@/components/HeaderDark";
import FooBar from "@/components/FooBar";

export default {
  data() {
    return {
      currentLanguage: ""
    }
  },
  components: {
    FooBar,
    Header,
    HeaderDark
  },
  mounted() {
    const changeLanguage = () => {
      const EN_LANGUAGE = 'en';
      const UA_LANGUAGE = 'ua';

      const getUserCountry = (url) => {
        return new Promise((resolve, reject) => {
          fetch(url)
              .then(res => res.json())
              .then(data => {
                return resolve(data.headers['Cf-Ipcountry']);
              })
              .catch((error) => {
                return reject(error);
              });
        })
      }

      const checkLanguage = (countryName) => countryName === UA_LANGUAGE.toUpperCase();

      const setLanguage = (language) => {
        this.$root.$i18n.locale = language;
        this.$forceUpdate();
      }

      getUserCountry('https://cloudflare-quic.com/b/headers').then((countryName) => {
        if (checkLanguage(countryName)) {
          setLanguage(UA_LANGUAGE);
        } else {
          setLanguage(EN_LANGUAGE);
        }
      }).catch((error) => {
        console.log(error);
      })
    }

    changeLanguage();
  }
}
</script>

<style lang="scss">
@import "base/styles/global";
@import "base/styles/cookies";

</style>
